import React from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation to check the current URL
import '../styles/Footer.css';
import { FaGithub, FaLinkedin, FaEnvelope } from 'react-icons/fa';

const Footer = () => {
  const location = useLocation(); // Get current route

  // Change "Uses" to "Home" if the user is on the /setup page
  const isSetupPage = location.pathname === "/setup";
  const buttonText = isSetupPage ? "Home" : "Uses";
  const buttonLink = isSetupPage ? "/" : "/setup";

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-left">
          <div className="social-links">
            <a href="https://github.com/Veryno" target="_blank" rel="noopener noreferrer" className="social-link">
              <FaGithub />
            </a>
            <a href="https://www.linkedin.com/in/yaacobabdullah/" target="_blank" rel="noopener noreferrer" className="social-link">
              <FaLinkedin />
            </a>
            <a href="mailto:yaacobabdullahh@gmail.com" className="social-link">
              <FaEnvelope />
            </a>
          </div>
        </div>
        <div className="footer-right">
          <a href={buttonLink} className="setup-link">{buttonText}</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
