import React from 'react';
import '../styles/Setup.css';

const Setup = () => {
  return (
    <div className="setup-container">
      <div className="setup-content">
        <div className="setup-section">
          <h3 className="setup-category">PC</h3>
          <p className="setup-text">
            AMD Ryzen 7 9800X3D <br />
            Thermalright AXP90-X47<br />
            ASRock B650I Lightning Wifi<br />
            G.Skill Flare X5 32 GB DDR5-6000 CL30 <br />
            Western Digital WD_Black SN850X 2 TB<br />
            Corsair SF1000 <br />
            Phanteks T30  <br />
            Western Digital WD_Black SN850X 2 TB<br />
            Noctua NA-FD1 <br />
            Noctua A9x14 <br />
            Formd t1 with CNC'd side panels
          </p>
        </div>

        <div className="setup-section">
          <h3 className="setup-category">DESK</h3>
          <p className="setup-text">
            Focusrite Solo 3rd Gen<br />
            Elgato LP Mic Arm<br />
            MXL 990 Blackout<br />
            AOC AG241QX 23.8" 2560 x 1440 144 Hz Monitor<br />
            LG 27GN950-B 27.0" 3840 x 2160 144 Hz Monitor<br />
            Wooting 60HE with tofu 60 redux and brass inlay<br />
            Finalmouse ULX<br />
            Pulsar Superglide Glass Mousepad<br />
            Beyerdynamic DT1990 Pro Headphones<br />
            Logitech BRIO Ultra HD Pro Webcam<br />
            Herman Miller Embody Gaming Edition Chair <br />
            Atlas Headrest
          </p>
        </div>

        <div className="setup-section">
          <h3 className="setup-category">Software</h3>
          <p className="setup-text">
            Visual Studio Code<br />
            Postman <br />
            Spotify <br />
            Discord<br />
            Zoom<br />
            Google Calendar<br />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Setup;
